// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xSiteConstants;
interface
uses
  xtypes;
// ------------------------------------------------------------------------------
const
  // ------------------------------------------------------------------------------
  tblContacts = 'xContacts';
  fldId = 'Id';
  fldLogin = 'Login';
  fldEmail = 'Email';
  fldNaam = 'Naam';
  fldZip = 'Zip';
  fldSleutel = 'Sleutel';
  fldShortDesc = 'ShortDesc';
  fldLongDesc = 'LongDesc';
  fldFree = 'Free';
  fldCreated = 'Created';
  fldCreatedBy = 'CreatedBy';
  fldModified = 'Modified';
  fldModifiedBy = 'ModifiedBy';
  fldOdooVersion = 'OdooVersion';
  fldIsTestAccount = 'IsTestAccount';
  fldLicenseStartdate = 'LicenseStartdate';
  fldLicenseEnddate = 'LicenseEnddate';
  fldLicenseOk = 'LicenseOk';
  fldTrialStartdate = 'TrialStartdate';
  fldTrialEnddate = 'TrialEnddate';
  fldTrialOk = 'TrialOk';
  fldNieuwsbrief = 'Nieuwsbrief';
  fldAdres = 'Adres';
  fldGemeente = 'Gemeente';
  fldLand = 'Land';
  fldRegistratie = 'Registratie';
  fldBedrijf = 'Bedrijf';
  fldToken = 'Token';
  fldLicenseKey = 'LicenseKey';
  fldHardwareId = 'HardwareId';
  fldVerified = 'Verified';
  fldVerificationDate = 'VerificationDate';
  // ------------------------------------------------------------------------------
  tblLogging = 'xLogging';
  fldlogMessage = 'logMessage';
  fldlogType = 'logType';
  fldLogTabel = 'logTabel';
  fldlogDateTime = 'logDateTime';
  fldlogId = 'logId';
  // ------------------------------------------------------------------------------
  xFormatOneDate = 'yyyy-mm-dd';
  xMySite = 'www.exentryc.be';
  xMyName = 'ExEntryC';
  // ------------------------------------------------------------------------------
  EmailPattern = '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$';
  // ------------------------------------------------------------------------------
  phpAddUser = 'https://exentryc.be/xAddUser.php';
  phpAllUsers = 'https://exentryc.be/xAllUsers.php';
  phpGetUser = 'https://exentryc.be/xLoginCheck.php';
  phpLogging = 'https://exentryc.be/xLog.php';
  phpSetVerified = 'https://exentryc.be/confirm.php';
  phpSMTPMail = 'https://exentryc.be/xStdMail.php';
  // ------------------------------------------------------------------------------
  cContactCreated = 'recordCreated';
  cContactFetchError = 'errFetchRecord';
  cNoRecords = 'errNoRecords';
  cConnectionFailed = 'errConnectionFailed';
  cQueryFailed = 'errQueryFailed';
  cQueryPreparationFailed = 'errQueryPreparationFailed';
  cEmailMissing = 'errEmailMissing';
  cParamsMissing = 'errParamsMissing';
  cInvalidLogging = 'errInvalidLogging';
  cMailSent = 'MailSent';
  cVerificationFailed = 'errVerificationFailed';
  cVerificationSuccess = 'errVerificationSuccess';
  cInvalidToken = 'errInvalidToken';
  cLoggingSuccess = 'errLoggingSuccess';
  cLoggingFailed = 'errLoggingFailed';
  cAddUserSuccess = 'errAddUserSuccess';
  cAddUserFailed = 'errAddUserFailed';
  cUnknown = 'errUnknown';
  cUnHandled = 'errUnHandled';
  cStatementPreparation = 'errStatementPreparation';
implementation
// ------------------------------------------------------------------------------
end.
