// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
program MySite;
// ------------------------------------------------------------------------------
uses
  WEBLib.Forms,
  fMain in 'Forms\fMain.pas'{*.html},
  xAppGlobals in 'Pas\xAppGlobals.pas',
  xSiteConstants in 'Pas\xSiteConstants.pas',
  xSiteResources in 'Pas\xSiteResources.pas',
  xAccount in 'Pas\xAccount.pas',
  xTypes in 'Pas\xTypes.pas',
  xLogMessage in 'Pas\xLogMessage.pas';

{$R *.res}
// ------------------------------------------------------------------------------
begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.
