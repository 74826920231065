// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
{ TODO : paswoordproblematiek nakijken }
{ TODO : Taal van de gebruiker opvragen in inputparameters }
// ------------------------------------------------------------------------------
unit fMain;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  WEBLib.Graphics,
  WEBLib.Forms,
  WEBLib.REST,
  Vcl.Controls,
  Vcl.StdCtrls,
  Vcl.ExtCtrls,
  WEBLib.StdCtrls,
  WEBLib.Dialogs,
  Vcl.Imaging.pngimage,
  WEBLib.ExtCtrls,
  WEBLib.RegularExpressions,
  WEBLib.Controls,
  WEBLib.JSON,
  Vcl.Dialogs,
  Web,
  JS,
  WEBLib.WebCtrls;
// ------------------------------------------------------------------------------
type
  TfrmMain = class(TForm)
    btnLogin: TButton;
    edtLogin: TEdit;
    edtPassWord: TEdit;
    btnNewAccount: TButton;
    lblLoginError: TLabel;
    btnExEntryC: TButton;
    edtNewLogin: TEdit;
    mnuGoHome: TLinkLabel;
    lblInsertError: TLabel;
    btnCancel: TButton;
    btnRegistreer: TButton;
    edtNewEmail: TEdit;
    mnuAbout: TLinkLabel;
    mnuContact: TLinkLabel;
    edtNewName: TEdit;
    edtNewConfirm: TEdit;
    edtNewMemo: TMemo;
    edtNewCompany: TEdit;
    edtNewCity: TEdit;
    edtNewInfo: TEdit;
    edtNewPassword: TEdit;
    chkNewsLetter: TCheckBox;
    Request: THttpRequest;
    edtNewAddress: TEdit;
    edtNewZip: TEdit;
    edtNewCountry: TEdit;
    edtTmpInfo: TMemo;
    divRegistreer: THTMLDiv;
    lblLoginSuccess: TLabel;
    chkTrial: TCheckBox;
    divNoPrijzen: THTMLDiv;
    divPrijzen: THTMLDiv;
    btnLogout: TButton;
    procedure InitControls;
    procedure WebFormShow(Sender: TObject);
    function isValidEmail(AEmail: string): boolean;
    procedure btnShowHideRegistration(Sender: TObject);
    procedure mnuGoHomeLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    [async]
    procedure doLoginCheck(Sender: TObject); async;
    [async]
    procedure LoadUsers(Sender: TObject); async;
    [async]
    procedure btnRegistreerClick(Sender: TObject); async;
    procedure doLogout(Sender: TObject);
    procedure mnuDisclaimerClick(Sender: TObject);
  protected procedure LoadDFMValues; override; end;
  // ------------------------------------------------------------------------------
var
  frmMain: TfrmMain;
  // ------------------------------------------------------------------------------
implementation
{$R *.dfm}
uses
  xSiteResources,
  xSiteConstants,
  xAccount,
  xAppGlobals;
// ------------------------------------------------------------------------------
procedure TfrmMain.btnCancelClick(Sender: TObject);
begin
  lblInsertError.Visible := False;
  lblInsertError.Caption := '';
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.btnRegistreerClick(Sender: TObject);
var
  HasErrors, AccountExists: boolean;
  lAccount: txAccount;
  procedure AddError(sErr: string);
  begin
    HasErrors := True;
    if Trim(lblInsertError.Caption) = EmptyStr then lblInsertError.Caption := sErr
    else lblInsertError.Caption := lblInsertError.Caption + #10 + sErr;
  end;
begin
  lblInsertError.Visible := False;
  lblInsertError.Caption := EmptyStr;
  HasErrors := False;
  lblInsertError.Caption := '';
  if Trim(edtNewLogin.Text) = EmptyStr then AddError(errNoLogin);
  if not isValidEmail(edtNewEmail.Text) then AddError(errNoValidEmail);
  if edtNewPassword.Text = EmptyStr then AddError(erroNoPassword)
  else if edtNewPassword.Text <> edtNewConfirm.Text then AddError(errNoMatch);
  if not HasErrors then
    begin
      AccountExists := False;
      ThisApp.LoginCheck(edtNewLogin.Text, edtNewEmail.Text, False, AccountExists);
      if AccountExists then AddError(ThisApp.xResult);
    end;
  lblInsertError.Visible := HasErrors;
  if not HasErrors then
    begin
      lAccount := txAccount.create;
      lAccount.xLogin := edtNewLogin.Text;
      lAccount.xEmail := edtNewEmail.Text;
      lAccount.xSleutel := edtNewPassword.Text; { TODO : encrypteren }
      lAccount.xNaam := edtNewName.Text;
      lAccount.xBedrijf := edtNewCompany.Text;
      lAccount.xAdres := edtNewAddress.Text;
      lAccount.xZip := edtNewZip.Text;
      lAccount.xGemeente := edtNewCity.Text;
      lAccount.xLand := edtNewCountry.Text;
      lAccount.xShortDesc := edtNewInfo.Text;
      lAccount.xLongDesc := edtNewMemo.Text;
      AWait(ThisApp.AddUser(lAccount));
      if not frmMain.lblInsertError.Visible then
        begin
          // lblLoginSuccess.Caption := 'Hallo, ' + ThisAccount.xNaam;
          // lblLoginSuccess.Visible := True;
          divRegistreer.Visible := False;
        end;
    end;
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.btnShowHideRegistration(Sender: TObject);
begin
  lblInsertError.Visible := False;
  chkNewsLetter.Checked := True;
  chkTrial.Checked := True;
  divRegistreer.Visible := True;
  mnuGoHomeLinkClick(self, '#top', sltID);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.doLoginCheck(Sender: TObject);
var
  IsValid: boolean;
begin
  IsValid := False;
  AWait(ThisApp.LoginCheck(edtLogin.Text, edtPassWord.Text, True, IsValid));
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.doLogout(Sender: TObject);
begin
  ThisAccount.Clear;
  btnLogout.Visible := False;
  btnLogin.Visible := True;
  btnNewAccount.Visible := True;
  divRegistreer.Visible := False;
  lblLoginError.Caption := EmptyStr;
  lblLoginSuccess.Caption := EmptyStr;
  lblLoginError.Visible := False;
  lblLoginSuccess.Visible := False;
  edtLogin.Text := EmptyStr;
  edtPassWord.Text := EmptyStr;
  divPrijzen.Visible := False;
  divNoPrijzen.Visible := True;
  ThisAccount.AccountNaarScherm(self);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.LoadUsers(Sender: TObject);
begin
  AWait(ThisApp.AllUsers);
  edtTmpInfo.Clear;
  edtTmpInfo.Lines.Add(ThisApp.xResult);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.InitControls;
begin
  divRegistreer.Visible := False;
  divPrijzen.Visible := False;
  divNoPrijzen.Visible := True;
  btnLogout.Visible := False;
  mnuGoHome.Caption := resGoHome;
  mnuAbout.Caption := resAboutExEntryC;
  mnuContact.Caption := resContactUs;
  lblLoginError.Caption := EmptyStr;
  edtLogin.TextHint := EmptyStr;
  edtPassWord.TextHint := EmptyStr;
  btnLogin.Caption := resLogin;
  btnLogout.Caption := resLogout;
  btnNewAccount.Caption := resNewAccount;
  lblInsertError.Caption := EmptyStr;
  edtNewLogin.TextHint := EmptyStr;
  edtNewEmail.TextHint := EmptyStr;
  edtNewPassword.TextHint := EmptyStr;
  edtNewConfirm.TextHint := EmptyStr;
  edtNewName.TextHint := EmptyStr;
  edtNewCompany.TextHint := EmptyStr;
  edtNewAddress.TextHint := EmptyStr;
  edtNewZip.TextHint := EmptyStr;
  edtNewCity.TextHint := EmptyStr;
  edtNewCountry.TextHint := EmptyStr;
  edtNewInfo.TextHint := EmptyStr;
  edtNewMemo.TextHint := EmptyStr;
  edtTmpInfo.TextHint := EmptyStr;
  chkNewsLetter.Caption := resNewsLetter;
end;
// ------------------------------------------------------------------------------
function TfrmMain.isValidEmail(AEmail: string): boolean;
begin
  Result := TRegEx.IsMatch(AEmail, EmailPattern);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  ThisApp.Free;
  ThisAccount.Free;
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.WebFormCreate(Sender: TObject);
begin
  // InitControls;
  ThisApp := tAppGlobals.create;
  ThisAccount := txAccount.create(nil);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.WebFormShow(Sender: TObject);
begin
  InitControls;
  btnExEntryC.Visible := (ThisApp.AppMode = Development);
  edtTmpInfo.Visible := btnExEntryC.Visible;
  edtTmpInfo.Lines.Clear;
  lblLoginError.Visible := False;
  lblLoginSuccess.Visible := False;
  // lblInsertError.Visible := False;
  mnuGoHomeLinkClick(self, '#top', sltID);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.mnuDisclaimerClick(Sender: TObject);
begin
  Application.Navigate('#disclaimer', ntPage);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.mnuGoHomeLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
begin
  Application.Navigate(Link, ntPage);
end;
// ------------------------------------------------------------------------------
procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblLoginError := TLabel.Create('lblLoginError');
  mnuGoHome := TLinkLabel.Create('mnuGoHome');
  lblInsertError := TLabel.Create('lblInsertError');
  mnuAbout := TLinkLabel.Create('mnuAbout');
  mnuContact := TLinkLabel.Create('mnuContact');
  lblLoginSuccess := TLabel.Create('lblLoginSuccess');
  btnLogin := TButton.Create('btnLogin');
  edtLogin := TEdit.Create('edtLogin');
  edtPassWord := TEdit.Create('edtPassWord');
  btnNewAccount := TButton.Create('btnNewAccount');
  btnExEntryC := TButton.Create('btnExEntryC');
  edtNewLogin := TEdit.Create('edtNewLogin');
  btnCancel := TButton.Create('btnCancel');
  btnRegistreer := TButton.Create('btnRegistreer');
  edtNewEmail := TEdit.Create('edtNewEmail');
  edtNewName := TEdit.Create('edtNewName');
  edtNewConfirm := TEdit.Create('edtNewConfirm');
  edtNewMemo := TMemo.Create('edtNewMemo');
  edtNewCompany := TEdit.Create('edtNewCompany');
  edtNewCity := TEdit.Create('edtNewCity');
  edtNewInfo := TEdit.Create('edtNewInfo');
  edtNewPassword := TEdit.Create('edtNewPassword');
  chkNewsLetter := TCheckBox.Create('chkNewsLetter');
  edtNewAddress := TEdit.Create('edtNewAddress');
  edtNewZip := TEdit.Create('edtNewZip');
  edtNewCountry := TEdit.Create('edtNewCountry');
  edtTmpInfo := TMemo.Create('edtTmpInfo');
  divRegistreer := THTMLDiv.Create('divRegistreer');
  chkTrial := TCheckBox.Create('chkTrial');
  divNoPrijzen := THTMLDiv.Create('divNoPrijzen');
  divPrijzen := THTMLDiv.Create('divPrijzen');
  btnLogout := TButton.Create('btnLogout');
  Request := THttpRequest.Create(Self);

  lblLoginError.BeforeLoadDFMValues;
  mnuGoHome.BeforeLoadDFMValues;
  lblInsertError.BeforeLoadDFMValues;
  mnuAbout.BeforeLoadDFMValues;
  mnuContact.BeforeLoadDFMValues;
  lblLoginSuccess.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  edtLogin.BeforeLoadDFMValues;
  edtPassWord.BeforeLoadDFMValues;
  btnNewAccount.BeforeLoadDFMValues;
  btnExEntryC.BeforeLoadDFMValues;
  edtNewLogin.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  btnRegistreer.BeforeLoadDFMValues;
  edtNewEmail.BeforeLoadDFMValues;
  edtNewName.BeforeLoadDFMValues;
  edtNewConfirm.BeforeLoadDFMValues;
  edtNewMemo.BeforeLoadDFMValues;
  edtNewCompany.BeforeLoadDFMValues;
  edtNewCity.BeforeLoadDFMValues;
  edtNewInfo.BeforeLoadDFMValues;
  edtNewPassword.BeforeLoadDFMValues;
  chkNewsLetter.BeforeLoadDFMValues;
  edtNewAddress.BeforeLoadDFMValues;
  edtNewZip.BeforeLoadDFMValues;
  edtNewCountry.BeforeLoadDFMValues;
  edtTmpInfo.BeforeLoadDFMValues;
  divRegistreer.BeforeLoadDFMValues;
  chkTrial.BeforeLoadDFMValues;
  divNoPrijzen.BeforeLoadDFMValues;
  divPrijzen.BeforeLoadDFMValues;
  btnLogout.BeforeLoadDFMValues;
  Request.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    Width := 1052;
    Height := 741;
    Caption := 'ExEntryC.be';
    CSSLibrary := cssBootstrap;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWhite;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormContainer := 'appcontent';
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblLoginError.SetParentComponent(Self);
    lblLoginError.Name := 'lblLoginError';
    lblLoginError.Left := 19;
    lblLoginError.Top := 111;
    lblLoginError.Width := 72;
    lblLoginError.Height := 16;
    lblLoginError.Caption := 'lblLoginError';
    lblLoginError.ElementLabelClassName := 'login-container';
    lblLoginError.ElementFont := efCSS;
    lblLoginError.ElementPosition := epIgnore;
    lblLoginError.HeightStyle := ssAuto;
    lblLoginError.HeightPercent := 100.000000000000000000;
    lblLoginError.WidthStyle := ssAuto;
    lblLoginError.WidthPercent := 100.000000000000000000;
    mnuGoHome.SetParentComponent(Self);
    mnuGoHome.Name := 'mnuGoHome';
    mnuGoHome.Left := 178;
    mnuGoHome.Top := 27;
    mnuGoHome.Width := 73;
    mnuGoHome.Height := 16;
    mnuGoHome.ElementFont := efCSS;
    mnuGoHome.HeightPercent := 100.000000000000000000;
    mnuGoHome.WidthPercent := 100.000000000000000000;
    SetEvent(mnuGoHome, Self, 'OnLinkClick', 'mnuGoHomeLinkClick');
    mnuGoHome.Caption := 'mnuGoHome';
    lblInsertError.SetParentComponent(Self);
    lblInsertError.Name := 'lblInsertError';
    lblInsertError.Left := 904;
    lblInsertError.Top := 55;
    lblInsertError.Width := 75;
    lblInsertError.Height := 16;
    lblInsertError.Caption := 'lblInsertError';
    lblInsertError.ElementFont := efCSS;
    lblInsertError.HeightStyle := ssAuto;
    lblInsertError.HeightPercent := 100.000000000000000000;
    lblInsertError.Visible := False;
    lblInsertError.WidthPercent := 100.000000000000000000;
    mnuAbout.SetParentComponent(Self);
    mnuAbout.Name := 'mnuAbout';
    mnuAbout.Left := 178;
    mnuAbout.Top := 49;
    mnuAbout.Width := 58;
    mnuAbout.Height := 16;
    mnuAbout.ElementFont := efCSS;
    mnuAbout.Enabled := False;
    mnuAbout.HeightPercent := 100.000000000000000000;
    mnuAbout.WidthPercent := 100.000000000000000000;
    mnuAbout.Caption := 'mnuAbout';
    mnuContact.SetParentComponent(Self);
    mnuContact.Name := 'mnuContact';
    mnuContact.Left := 178;
    mnuContact.Top := 71;
    mnuContact.Width := 68;
    mnuContact.Height := 16;
    mnuContact.ElementFont := efCSS;
    mnuContact.Enabled := False;
    mnuContact.HeightPercent := 100.000000000000000000;
    mnuContact.WidthPercent := 100.000000000000000000;
    mnuContact.Caption := 'mnuContact';
    lblLoginSuccess.SetParentComponent(Self);
    lblLoginSuccess.Name := 'lblLoginSuccess';
    lblLoginSuccess.Left := 19;
    lblLoginSuccess.Top := 195;
    lblLoginSuccess.Width := 89;
    lblLoginSuccess.Height := 16;
    lblLoginSuccess.Caption := 'lblLoginSuccess';
    lblLoginSuccess.ElementFont := efCSS;
    lblLoginSuccess.ElementPosition := epIgnore;
    lblLoginSuccess.HeightStyle := ssAuto;
    lblLoginSuccess.HeightPercent := 100.000000000000000000;
    lblLoginSuccess.WidthStyle := ssAuto;
    lblLoginSuccess.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 19;
    btnLogin.Top := 133;
    btnLogin.Width := 96;
    btnLogin.Height := 25;
    btnLogin.Caption := 'btnLogin';
    btnLogin.ChildOrder := 2;
    btnLogin.ElementClassName := 'btn btn-light';
    btnLogin.ElementFont := efCSS;
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'doLoginCheck');
    edtLogin.SetParentComponent(Self);
    edtLogin.Name := 'edtLogin';
    edtLogin.Left := 19;
    edtLogin.Top := 55;
    edtLogin.Width := 96;
    edtLogin.Height := 22;
    edtLogin.ChildOrder := 2;
    edtLogin.ElementFont := efCSS;
    edtLogin.HeightStyle := ssAuto;
    edtLogin.HeightPercent := 100.000000000000000000;
    edtLogin.WidthPercent := 100.000000000000000000;
    edtPassWord.SetParentComponent(Self);
    edtPassWord.Name := 'edtPassWord';
    edtPassWord.Left := 19;
    edtPassWord.Top := 83;
    edtPassWord.Width := 96;
    edtPassWord.Height := 22;
    edtPassWord.ChildOrder := 2;
    edtPassWord.ElementFont := efCSS;
    edtPassWord.HeightStyle := ssAuto;
    edtPassWord.HeightPercent := 100.000000000000000000;
    edtPassWord.WidthPercent := 100.000000000000000000;
    btnNewAccount.SetParentComponent(Self);
    btnNewAccount.Name := 'btnNewAccount';
    btnNewAccount.Left := 19;
    btnNewAccount.Top := 164;
    btnNewAccount.Width := 96;
    btnNewAccount.Height := 25;
    btnNewAccount.Caption := 'btnNewAccount';
    btnNewAccount.ChildOrder := 2;
    btnNewAccount.ElementClassName := 'btn btn-light';
    btnNewAccount.ElementFont := efCSS;
    btnNewAccount.HeightStyle := ssAuto;
    btnNewAccount.HeightPercent := 100.000000000000000000;
    btnNewAccount.WidthPercent := 100.000000000000000000;
    SetEvent(btnNewAccount, Self, 'OnClick', 'btnShowHideRegistration');
    btnExEntryC.SetParentComponent(Self);
    btnExEntryC.Name := 'btnExEntryC';
    btnExEntryC.Left := 19;
    btnExEntryC.Top := 24;
    btnExEntryC.Width := 96;
    btnExEntryC.Height := 25;
    btnExEntryC.Caption := 'ExEntryCOnly';
    btnExEntryC.ChildOrder := 6;
    btnExEntryC.ElementClassName := 'btn btn-light';
    btnExEntryC.ElementFont := efCSS;
    btnExEntryC.ElementPosition := epIgnore;
    btnExEntryC.HeightStyle := ssAuto;
    btnExEntryC.HeightPercent := 100.000000000000000000;
    btnExEntryC.WidthStyle := ssAuto;
    btnExEntryC.WidthPercent := 100.000000000000000000;
    SetEvent(btnExEntryC, Self, 'OnClick', 'LoadUsers');
    edtNewLogin.SetParentComponent(Self);
    edtNewLogin.Name := 'edtNewLogin';
    edtNewLogin.Left := 802;
    edtNewLogin.Top := 117;
    edtNewLogin.Width := 96;
    edtNewLogin.Height := 25;
    edtNewLogin.ChildOrder := 19;
    edtNewLogin.ElementFont := efCSS;
    edtNewLogin.ElementPosition := epIgnore;
    edtNewLogin.HeightStyle := ssAuto;
    edtNewLogin.HeightPercent := 100.000000000000000000;
    edtNewLogin.WidthStyle := ssAuto;
    edtNewLogin.WidthPercent := 100.000000000000000000;
    btnCancel.SetParentComponent(Self);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 802;
    btnCancel.Top := 55;
    btnCancel.Width := 96;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Annuleer';
    btnCancel.ChildOrder := 13;
    btnCancel.ElementClassName := 'btn btn-light';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    btnRegistreer.SetParentComponent(Self);
    btnRegistreer.Name := 'btnRegistreer';
    btnRegistreer.Left := 802;
    btnRegistreer.Top := 86;
    btnRegistreer.Width := 96;
    btnRegistreer.Height := 25;
    btnRegistreer.Caption := 'Bevestig';
    btnRegistreer.ChildOrder := 14;
    btnRegistreer.ElementClassName := 'btn btn-light';
    btnRegistreer.ElementFont := efCSS;
    btnRegistreer.HeightStyle := ssAuto;
    btnRegistreer.HeightPercent := 100.000000000000000000;
    btnRegistreer.WidthPercent := 100.000000000000000000;
    SetEvent(btnRegistreer, Self, 'OnClick', 'btnRegistreerClick');
    edtNewEmail.SetParentComponent(Self);
    edtNewEmail.Name := 'edtNewEmail';
    edtNewEmail.Left := 802;
    edtNewEmail.Top := 144;
    edtNewEmail.Width := 96;
    edtNewEmail.Height := 22;
    edtNewEmail.ChildOrder := 16;
    edtNewEmail.ElementClassName := 'form-control';
    edtNewEmail.ElementFont := efCSS;
    edtNewEmail.HeightStyle := ssAuto;
    edtNewEmail.HeightPercent := 100.000000000000000000;
    edtNewEmail.WidthPercent := 100.000000000000000000;
    edtNewName.SetParentComponent(Self);
    edtNewName.Name := 'edtNewName';
    edtNewName.Left := 802;
    edtNewName.Top := 172;
    edtNewName.Width := 96;
    edtNewName.Height := 22;
    edtNewName.ChildOrder := 20;
    edtNewName.ElementClassName := 'form-control';
    edtNewName.ElementFont := efCSS;
    edtNewName.HeightStyle := ssAuto;
    edtNewName.HeightPercent := 100.000000000000000000;
    edtNewName.WidthPercent := 100.000000000000000000;
    edtNewConfirm.SetParentComponent(Self);
    edtNewConfirm.Name := 'edtNewConfirm';
    edtNewConfirm.Left := 802;
    edtNewConfirm.Top := 231;
    edtNewConfirm.Width := 96;
    edtNewConfirm.Height := 25;
    edtNewConfirm.ChildOrder := 18;
    edtNewConfirm.ElementFont := efCSS;
    edtNewConfirm.ElementPosition := epIgnore;
    edtNewConfirm.HeightStyle := ssAuto;
    edtNewConfirm.HeightPercent := 100.000000000000000000;
    edtNewConfirm.PasswordChar := '_';
    edtNewConfirm.WidthStyle := ssAuto;
    edtNewConfirm.WidthPercent := 100.000000000000000000;
    edtNewMemo.SetParentComponent(Self);
    edtNewMemo.Name := 'edtNewMemo';
    edtNewMemo.Left := 802;
    edtNewMemo.Top := 519;
    edtNewMemo.Width := 151;
    edtNewMemo.Height := 74;
    edtNewMemo.ElementFont := efCSS;
    edtNewMemo.ElementPosition := epIgnore;
    edtNewMemo.HeightStyle := ssAuto;
    edtNewMemo.HeightPercent := 100.000000000000000000;
    edtNewMemo.SelLength := 0;
    edtNewMemo.SelStart := 0;
    edtNewMemo.WidthStyle := ssAuto;
    edtNewMemo.WidthPercent := 100.000000000000000000;
    edtNewCompany.SetParentComponent(Self);
    edtNewCompany.Name := 'edtNewCompany';
    edtNewCompany.Left := 802;
    edtNewCompany.Top := 386;
    edtNewCompany.Width := 96;
    edtNewCompany.Height := 25;
    edtNewCompany.ChildOrder := 20;
    edtNewCompany.ElementFont := efCSS;
    edtNewCompany.ElementPosition := epIgnore;
    edtNewCompany.HeightStyle := ssAuto;
    edtNewCompany.HeightPercent := 100.000000000000000000;
    edtNewCompany.WidthStyle := ssAuto;
    edtNewCompany.WidthPercent := 100.000000000000000000;
    edtNewCity.SetParentComponent(Self);
    edtNewCity.Name := 'edtNewCity';
    edtNewCity.Left := 802;
    edtNewCity.Top := 324;
    edtNewCity.Width := 96;
    edtNewCity.Height := 25;
    edtNewCity.ChildOrder := 21;
    edtNewCity.ElementFont := efCSS;
    edtNewCity.ElementPosition := epIgnore;
    edtNewCity.HeightStyle := ssAuto;
    edtNewCity.HeightPercent := 100.000000000000000000;
    edtNewCity.WidthStyle := ssAuto;
    edtNewCity.WidthPercent := 100.000000000000000000;
    edtNewInfo.SetParentComponent(Self);
    edtNewInfo.Name := 'edtNewInfo';
    edtNewInfo.Left := 802;
    edtNewInfo.Top := 417;
    edtNewInfo.Width := 96;
    edtNewInfo.Height := 25;
    edtNewInfo.ChildOrder := 22;
    edtNewInfo.ElementFont := efCSS;
    edtNewInfo.ElementPosition := epIgnore;
    edtNewInfo.HeightStyle := ssAuto;
    edtNewInfo.HeightPercent := 100.000000000000000000;
    edtNewInfo.WidthStyle := ssAuto;
    edtNewInfo.WidthPercent := 100.000000000000000000;
    edtNewPassword.SetParentComponent(Self);
    edtNewPassword.Name := 'edtNewPassword';
    edtNewPassword.Left := 802;
    edtNewPassword.Top := 200;
    edtNewPassword.Width := 96;
    edtNewPassword.Height := 25;
    edtNewPassword.ChildOrder := 23;
    edtNewPassword.ElementFont := efCSS;
    edtNewPassword.ElementPosition := epIgnore;
    edtNewPassword.HeightStyle := ssAuto;
    edtNewPassword.HeightPercent := 100.000000000000000000;
    edtNewPassword.PasswordChar := '_';
    edtNewPassword.WidthStyle := ssAuto;
    edtNewPassword.WidthPercent := 100.000000000000000000;
    chkNewsLetter.SetParentComponent(Self);
    chkNewsLetter.Name := 'chkNewsLetter';
    chkNewsLetter.Left := 802;
    chkNewsLetter.Top := 448;
    chkNewsLetter.Width := 96;
    chkNewsLetter.Height := 25;
    chkNewsLetter.Caption := 'chkNewsLetter';
    chkNewsLetter.Checked := True;
    chkNewsLetter.ChildOrder := 24;
    chkNewsLetter.ElementButtonClassName := 'custom-control-input';
    chkNewsLetter.ElementLabelClassName := 'custom-control-label';
    chkNewsLetter.ElementFont := efCSS;
    chkNewsLetter.ElementPosition := epIgnore;
    chkNewsLetter.HeightStyle := ssAuto;
    chkNewsLetter.HeightPercent := 100.000000000000000000;
    chkNewsLetter.State := cbChecked;
    chkNewsLetter.WidthStyle := ssAuto;
    chkNewsLetter.WidthPercent := 100.000000000000000000;
    edtNewAddress.SetParentComponent(Self);
    edtNewAddress.Name := 'edtNewAddress';
    edtNewAddress.Left := 802;
    edtNewAddress.Top := 262;
    edtNewAddress.Width := 96;
    edtNewAddress.Height := 25;
    edtNewAddress.ChildOrder := 25;
    edtNewAddress.ElementFont := efCSS;
    edtNewAddress.ElementPosition := epIgnore;
    edtNewAddress.HeightStyle := ssAuto;
    edtNewAddress.HeightPercent := 100.000000000000000000;
    edtNewAddress.WidthStyle := ssAuto;
    edtNewAddress.WidthPercent := 100.000000000000000000;
    edtNewZip.SetParentComponent(Self);
    edtNewZip.Name := 'edtNewZip';
    edtNewZip.Left := 802;
    edtNewZip.Top := 293;
    edtNewZip.Width := 96;
    edtNewZip.Height := 25;
    edtNewZip.ChildOrder := 26;
    edtNewZip.ElementFont := efCSS;
    edtNewZip.ElementPosition := epIgnore;
    edtNewZip.HeightStyle := ssAuto;
    edtNewZip.HeightPercent := 100.000000000000000000;
    edtNewZip.WidthStyle := ssAuto;
    edtNewZip.WidthPercent := 100.000000000000000000;
    edtNewCountry.SetParentComponent(Self);
    edtNewCountry.Name := 'edtNewCountry';
    edtNewCountry.Left := 802;
    edtNewCountry.Top := 355;
    edtNewCountry.Width := 96;
    edtNewCountry.Height := 25;
    edtNewCountry.ChildOrder := 27;
    edtNewCountry.ElementFont := efCSS;
    edtNewCountry.ElementPosition := epIgnore;
    edtNewCountry.HeightStyle := ssAuto;
    edtNewCountry.HeightPercent := 100.000000000000000000;
    edtNewCountry.WidthStyle := ssAuto;
    edtNewCountry.WidthPercent := 100.000000000000000000;
    edtTmpInfo.SetParentComponent(Self);
    edtTmpInfo.Name := 'edtTmpInfo';
    edtTmpInfo.Left := 178;
    edtTmpInfo.Top := 115;
    edtTmpInfo.Width := 151;
    edtTmpInfo.Height := 74;
    edtTmpInfo.ElementFont := efCSS;
    edtTmpInfo.ElementPosition := epIgnore;
    edtTmpInfo.HeightStyle := ssAuto;
    edtTmpInfo.HeightPercent := 100.000000000000000000;
    edtTmpInfo.SelLength := 0;
    edtTmpInfo.SelStart := 0;
    edtTmpInfo.WidthStyle := ssAuto;
    edtTmpInfo.WidthPercent := 100.000000000000000000;
    divRegistreer.SetParentComponent(Self);
    divRegistreer.Name := 'divRegistreer';
    divRegistreer.Left := 802;
    divRegistreer.Top := 24;
    divRegistreer.Width := 96;
    divRegistreer.Height := 25;
    divRegistreer.HeightStyle := ssAuto;
    divRegistreer.WidthStyle := ssAuto;
    divRegistreer.ChildOrder := 28;
    divRegistreer.ElementPosition := epIgnore;
    divRegistreer.ElementFont := efCSS;
    divRegistreer.Role := '';
    divRegistreer.Visible := False;
    chkTrial.SetParentComponent(Self);
    chkTrial.Name := 'chkTrial';
    chkTrial.Left := 802;
    chkTrial.Top := 479;
    chkTrial.Width := 96;
    chkTrial.Height := 25;
    chkTrial.Caption := 'chkTrial';
    chkTrial.Checked := True;
    chkTrial.ChildOrder := 29;
    chkTrial.ElementButtonClassName := 'custom-control-input';
    chkTrial.ElementLabelClassName := 'custom-control-label';
    chkTrial.ElementFont := efCSS;
    chkTrial.ElementPosition := epIgnore;
    chkTrial.HeightStyle := ssAuto;
    chkTrial.HeightPercent := 100.000000000000000000;
    chkTrial.State := cbChecked;
    chkTrial.WidthStyle := ssAuto;
    chkTrial.WidthPercent := 100.000000000000000000;
    divNoPrijzen.SetParentComponent(Self);
    divNoPrijzen.Name := 'divNoPrijzen';
    divNoPrijzen.Left := 904;
    divNoPrijzen.Top := 108;
    divNoPrijzen.Width := 96;
    divNoPrijzen.Height := 25;
    divNoPrijzen.HeightStyle := ssAuto;
    divNoPrijzen.WidthStyle := ssAuto;
    divNoPrijzen.ChildOrder := 29;
    divNoPrijzen.ElementPosition := epIgnore;
    divNoPrijzen.ElementFont := efCSS;
    divNoPrijzen.Role := '';
    divPrijzen.SetParentComponent(Self);
    divPrijzen.Name := 'divPrijzen';
    divPrijzen.Left := 904;
    divPrijzen.Top := 77;
    divPrijzen.Width := 96;
    divPrijzen.Height := 25;
    divPrijzen.HeightStyle := ssAuto;
    divPrijzen.WidthStyle := ssAuto;
    divPrijzen.ChildOrder := 30;
    divPrijzen.ElementPosition := epIgnore;
    divPrijzen.ElementFont := efCSS;
    divPrijzen.Role := '';
    btnLogout.SetParentComponent(Self);
    btnLogout.Name := 'btnLogout';
    btnLogout.Left := 8;
    btnLogout.Top := 217;
    btnLogout.Width := 96;
    btnLogout.Height := 25;
    btnLogout.Caption := 'btnLogout';
    btnLogout.ChildOrder := 31;
    btnLogout.ElementClassName := 'btn btn-light';
    btnLogout.ElementFont := efCSS;
    btnLogout.ElementPosition := epIgnore;
    btnLogout.HeightStyle := ssAuto;
    btnLogout.HeightPercent := 100.000000000000000000;
    btnLogout.WidthStyle := ssAuto;
    btnLogout.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogout, Self, 'OnClick', 'doLogout');
    Request.SetParentComponent(Self);
    Request.Name := 'Request';
    Request.Left := 392;
    Request.Top := 72;
  finally
    lblLoginError.AfterLoadDFMValues;
    mnuGoHome.AfterLoadDFMValues;
    lblInsertError.AfterLoadDFMValues;
    mnuAbout.AfterLoadDFMValues;
    mnuContact.AfterLoadDFMValues;
    lblLoginSuccess.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    edtLogin.AfterLoadDFMValues;
    edtPassWord.AfterLoadDFMValues;
    btnNewAccount.AfterLoadDFMValues;
    btnExEntryC.AfterLoadDFMValues;
    edtNewLogin.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    btnRegistreer.AfterLoadDFMValues;
    edtNewEmail.AfterLoadDFMValues;
    edtNewName.AfterLoadDFMValues;
    edtNewConfirm.AfterLoadDFMValues;
    edtNewMemo.AfterLoadDFMValues;
    edtNewCompany.AfterLoadDFMValues;
    edtNewCity.AfterLoadDFMValues;
    edtNewInfo.AfterLoadDFMValues;
    edtNewPassword.AfterLoadDFMValues;
    chkNewsLetter.AfterLoadDFMValues;
    edtNewAddress.AfterLoadDFMValues;
    edtNewZip.AfterLoadDFMValues;
    edtNewCountry.AfterLoadDFMValues;
    edtTmpInfo.AfterLoadDFMValues;
    divRegistreer.AfterLoadDFMValues;
    chkTrial.AfterLoadDFMValues;
    divNoPrijzen.AfterLoadDFMValues;
    divPrijzen.AfterLoadDFMValues;
    btnLogout.AfterLoadDFMValues;
    Request.AfterLoadDFMValues;
  end;
end;

end.
