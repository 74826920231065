// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xAppGlobals;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  JS,
  Web,
  WebLib.JSON,
  WebLib.Graphics,
  WebLib.Controls,
  WebLib.Forms,
  WebLib.Dialogs,
  WebLib.REST,
  WebLib.StdCtrls,
  System.DateUtils,
  Vcl.StdCtrls,
  Vcl.Controls,
  xTypes,
  xLogMessage,
  xAccount;
// ------------------------------------------------------------------------------
type
  tMode = (Development, Production);
  tAppGlobals = class(tComponent)
    fResult: string;
    fErrors: TDictionary<txErrorType, txError>;
    fMessages: TDictionary<string, txMessage>;
    // fCurAccount: txAccount;
    function AppMode: tMode;
    function GetValidUser(var sNaam: string): boolean;
    function PrettyPrint(const sJson: string): string;
    procedure SetValidUser(const isValid: boolean; const sNaam: string);
    procedure AddUserResponse(Sender: TObject; AResponse: string);
    procedure doLogResponse(Sender: TObject; AResponse: string);
    procedure AllUsersReponse(Sender: TObject; AResponse: string);
    procedure GetUserResponse(Sender: TObject; AResponse: string);
    procedure GetUserError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
    function GetErrorDescription(errType: txErrorType): string;
    function FindErrorTypeByDescription(const ADescription: string): txErrorType;
    procedure InvalidPHPResponse(a, b: string);
  private
    constructor Create(AOwner: tComponent); override;
    destructor Destroy; override;
    procedure ClearLoginLabels;
    procedure ShowLabels;
  public
    [async]
    function SendDownloadLink(const pto, pSubject, pToken, pUserName: string): string; async;
    [async]
    function AddLogMessage(const Logtype: txLogType; const aTableName, aMessage: string): string; async;
    [async]
    function AddUser(var NewAccount: txAccount): string; async;
    [async]
    function AllUsers: string; async;
    [async]
    function LoginCheck(const pLogin, pSleutel: string; const isLogin: boolean; var Exists: boolean): string; async;
    // property xCurrentAccount: txAccount read fcurAccount write fcurAccount;
    property xResult: string read fResult write fResult;
    property xMessages: TDictionary<string, txMessage> read fMessages write fMessages;
    property xErrors: TDictionary<txErrorType, txError> read fErrors write fErrors;
  end;
  // ------------------------------------------------------------------------------
var
  ThisApp: tAppGlobals;
  ThisAccount: txAccount;
  // ------------------------------------------------------------------------------
function GetJsonValueAsString(Obj: TJSONObject; const Key: string; const DefaultValue: string = ''): string;
function GetJsonValueAsDate(Obj: TJSONObject; const Key: string; const DefaultValue: TDate = 0): TDate;
function GetJsonValueAsBoolean(Obj: TJSONObject; const Key: string; const DefaultValue: boolean = False): boolean;
function GetJsonValueAsDouble(Obj: TJSONObject; const Key: string; const DefaultValue: double = 0): double;
// ------------------------------------------------------------------------------
implementation
uses
  fMain,
  WebLib.Storage,
  xSiteConstants;
// ------------------------------------------------------------------------------
procedure tAppGlobals.AddUserResponse(Sender: TObject; AResponse: string);
begin
  fResult := AResponse;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.doLogResponse(Sender: TObject; AResponse: string);
begin
  fResult := AResponse;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.AppMode: tMode;
begin
  if SameText(TLocalStorage.GetValue('Mode'), 'Development') then Result := Development
  else Result := Production;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.ClearLoginLabels;
begin
  with frmMain do
    begin
      with lblLoginError do
        begin
          Visible := False;
          Caption := EmptyStr
        end;
      with lblLoginSuccess do
        begin
          Visible := False;
          Caption := EmptyStr
        end;
      edtTmpInfo.Clear;
    end;
end;
// ------------------------------------------------------------------------------
constructor tAppGlobals.Create(AOwner: tComponent);
var
  APair: TPair<txErrorType, txError>;
  function AddError(const sOmschrijving: string; const tLevel: txErrorLevel): txError;
  begin
    Result.Omschrijving := sOmschrijving;
    Result.Level := tLevel;
  end;
  function AddMessage(const tError: txErrorType; const tLevel: txErrorLevel): txMessage;
  begin
    Result.Error := tError;
    Result.Level := tLevel;
  end;
begin
  inherited;
  xErrors := TDictionary<txErrorType, txError>.Create;
  // dit geraakt niet gecompileerd:  xErrors.Add(errNoRecords, txError(Omschrijving: cNoRecords, Level: elError));
  xErrors.Add(errNoRecords, AddError(cNoRecords, elError));
  xErrors.Add(errUnknown, AddError(cunknown, elFatal));
  xErrors.Add(errConnectionFailed, AddError(cConnectionFailed, elError));
  xErrors.Add(errQueryFailed, AddError(cQueryFailed, elError));
  xErrors.Add(errQueryPreparationFailed, AddError(cQueryPreparationFailed, elError));
  xErrors.Add(errEmailMissing, AddError(cEmailMissing, elError));
  xErrors.Add(errParamsMissing, AddError(cParamsMissing, elError));
  xErrors.Add(errInvalidLogging, AddError(cInvalidLogging, elError));
  xErrors.Add(errMailSent, AddError(cMailSent, elError));
  xErrors.Add(errVerificationFailed, AddError(cVerificationFailed, elError));
  xErrors.Add(errVerificationSuccess, AddError(cVerificationSuccess, elError));
  xErrors.Add(errInvalidToken, AddError(cInvalidToken, elError));
  xErrors.Add(errLoggingSuccess, AddError(cLoggingSuccess, elSuccess));
  xErrors.Add(errLoggingFailed, AddError(cLoggingFailed, elError));
  xErrors.Add(errAddUserSuccess, AddError(cAddUserSuccess, elSuccess));
  xErrors.Add(errAddUserFailed, AddError(cAddUserFailed, elError));
  xErrors.Add(errStatementPreparation, AddError(cStatementPreparation, elError));
  xErrors.Add(errUnhandled, AddError(cUnhandled, elFatal));
  xMessages := TDictionary<string, txMessage>.Create;
  for APair in xErrors do
    begin
      xMessages.Add(APair.Value.Omschrijving, AddMessage(APair.Key, APair.Value.Level));
    end;
end;
// ------------------------------------------------------------------------------
destructor tAppGlobals.Destroy;
begin
  fErrors.Free;
  fMessages.Free;
  inherited;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.GetValidUser(var sNaam: string): boolean;
var
  ts: string;
begin
  ts := TSessionStorage.GetValue('IsMember');
  if ts = EmptyStr then Result := False
  else Result := StrToBool(ts);
  if Result then sNaam := TSessionStorage.GetValue('MemberName');
end;
// ------------------------------------------------------------------------------
function tAppGlobals.SendDownloadLink(const pto, pSubject, pToken, pUserName: string): string;
var
  isObject: TJSONObject;
  isArray: TJSONArray;
  resMail: TJSXMLHttpRequest;
  REQMail: THttpRequest;
  FullUrl: string;
  Returned: string;
begin
  REQMail := THttpRequest.Create(Application);
  try
    REQMail.Command := httpGet;
    REQMail.Headers.Clear;
    REQMail.Timeout := 5000;
    FullUrl := phpSMTPMail + '?Subject=' + pSubject + '&Email=' + pto + '&Token=' + pToken + '&UserName=' + StringReplace(pUserName, ' ', '%20', [rfReplaceAll]);
    REQMail.URL := FullUrl;
    resMail := await(TJSXMLHttpRequest, REQMail.Perform());
  finally REQMail.Free;
  end;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.AddLogMessage(const Logtype: txLogType; const aTableName, aMessage: string): string;
var
  REQAddLogMessage: THttpRequest;
  resAddLogMessage: TJSXMLHttpRequest;
  JSONObject, DetailObject, newRecord: TJSONObject;
  JSONArray: TJSONArray;
  NewMessage: txLogMessage;
begin
  REQAddLogMessage := THttpRequest.Create(Application);
  try
    REQAddLogMessage.OnResponse := doLogResponse;
    REQAddLogMessage.Command := httpPOST;
    REQAddLogMessage.Headers.Clear;
    with txLogMessage.Create(nil) do
      begin
        xLogType := Logtype;
        xLogTabel := aTableName;
        xLogMessage := aMessage;
        REQAddLogMessage.URL := phpLogging + UrlAddParams;
        Free;
      end;
    resAddLogMessage := await(TJSXMLHttpRequest, REQAddLogMessage.Perform());
    Result := trim(string(resAddLogMessage.response));
    JSONObject := TJSONObject.ParseJSONValue(Result) as TJSONObject;
    try
      if assigned(JSONObject) then
        begin
          if JSONObject.GetValue('success') <> nil then
            begin
              JSONArray := JSONObject.GetValue('success') as TJSONArray;
              DetailObject := JSONArray.Items[0] as TJSONObject;
            end
          else if JSONObject.GetValue('error') <> nil then
            begin
              JSONArray := JSONObject.GetValue('error') as TJSONArray;
              if JSONArray <> null then
                begin
                  DetailObject := JSONArray.Items[0] as TJSONObject;
                end;
            end
          else
            begin
              InvalidPHPResponse('tAppGlobals.LoginCheck', Result);
            end;
        end;
    finally JSONObject.Free;
    end;
  except Result := GetErrorDescription(errUnhandled);
  end;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.AddUser(var NewAccount: txAccount): string;
// nieuw record in tblContacts
var
  REQAddUser: THttpRequest;
  resAddUser: TJSXMLHttpRequest;
  JSONObject, DetailObject, newRecord: TJSONObject;
  JSONArray: TJSONArray;
  body: string;
begin
  ClearLoginLabels;
  REQAddUser := THttpRequest.Create(Application);
  try
    REQAddUser.OnResponse := AddUserResponse { wordt uitgevoerd in reqAddUser.Perform() };
    REQAddUser.Command := httpPOST;
    REQAddUser.Headers.Clear;
    REQAddUser.URL := phpAddUser + NewAccount.UrlAddParams;
    resAddUser := await(TJSXMLHttpRequest, REQAddUser.Perform());
    Result := trim(string(resAddUser.response));
    JSONObject := TJSONObject.ParseJSONValue(Result) as TJSONObject;
    try
      if assigned(JSONObject) then
        begin
          if JSONObject.GetValue('success') <> nil then
            begin
              JSONArray := JSONObject.GetValue('success') as TJSONArray;
              DetailObject := JSONArray.Items[0] as TJSONObject;
              newRecord := DetailObject.GetValue('record') as TJSONObject;
              ThisAccount.LoadFromJson(newRecord.ToString);
              AddLogMessage(Nieuw, tblContacts, newRecord.ToString);
              body := ThisAccount.xToken;
              SendDownloadLink(ThisAccount.xEmail, 'VisionOn Odoo: info (' + DateTimeToStr(Now) + ').', body, ThisAccount.xNaam);
            end
          else if JSONObject.GetValue('error') <> nil then
            begin
              JSONArray := JSONObject.GetValue('error') as TJSONArray;
              if JSONArray <> null then
                begin
                  DetailObject := JSONArray.Items[0] as TJSONObject;
                  frmMain.lblInsertError.Caption := DetailObject.GetValue('info').Value;
                  AddLogMessage(Fout, tblContacts, NewAccount.xlogin + '/' + NewAccount.xEmail + ':' + DetailObject.GetValue('info').Value);
                end;
            end
          else
            begin
              frmMain.lblLoginError.Caption := 'Systeemfout [001]. Contacteer ons.';
              InvalidPHPResponse('tAppGlobals.LoginCheck', Result);
              AddLogMessage(Fataal, tblContacts, NewAccount.xlogin + '/' + NewAccount.xEmail + ':' + Result);
            end;
        end;
    finally JSONObject.Free;
    end;
  except Result := GetErrorDescription(errUnhandled);
  end;
  frmMain.lblInsertError.Visible := (frmMain.lblInsertError.Caption <> EmptyStr);
end;
// ------------------------------------------------------------------------------
function tAppGlobals.LoginCheck(const pLogin, pSleutel: string; const isLogin: boolean; var Exists: boolean): string;
var
  REQGetUser: THttpRequest;
  resGetUser: TJSXMLHttpRequest;
  FullUrl: string;
  JSONObject, DetailObject: TJSONObject;
  JSONArray: TJSONArray;
begin
  Exists := False;
  ClearLoginLabels;
  REQGetUser := THttpRequest.Create(Application);
  try
    REQGetUser.OnResponse := GetUserResponse { wordt uitgevoerd in UserExistsRequest.Perform() };
    REQGetUser.OnError := GetUserError;
    REQGetUser.Command := httpPOST;
    REQGetUser.Headers.Clear;
    FullUrl := phpGetUser + '?' + fldLogin + '=' + pLogin + '&&' + fldSleutel + '=' + pSleutel;
    REQGetUser.URL := FullUrl;
    resGetUser := await(TJSXMLHttpRequest, REQGetUser.Perform());
    Result := trim(string(resGetUser.response));
    JSONObject := TJSONObject.ParseJSONValue(Result) as TJSONObject;
    try
      if assigned(JSONObject) then
        begin
          if JSONObject.GetValue('success') <> nil then
            begin
              JSONArray := JSONObject.GetValue('success') as TJSONArray;
              DetailObject := JSONArray.Items[0] as TJSONObject;
              ThisAccount.LoadFromJson(DetailObject.ToString);
              if isLogin then
                begin
                  AddLogMessage(Success, 'login', Format('%s %s "%s"', [ThisAccount.xlogin, ThisAccount.xEmail, ThisAccount.Name]));
                  frmMain.lblLoginSuccess.Caption := 'Hallo, ' + ThisAccount.xNaam;
                end;
              Exists := True;
            end
          else if JSONObject.GetValue('error') <> nil then
            begin
              JSONArray := JSONObject.GetValue('error') as TJSONArray;
              if JSONArray <> null then
                begin
                  DetailObject := JSONArray.Items[0] as TJSONObject;
                  if isLogin then
                    begin
                      frmMain.edtTmpInfo.Lines.Add(Result);
                      xResult := DetailObject.GetValue('info').Value;
                      AddLogMessage(Fout, 'login', Format('%s', [pLogin]));
                    end;
                end;
            end
          else
            begin
              if isLogin then
                begin
                  frmMain.lblLoginError.Caption := 'Systeemfout [002]. Contacteer ons.';
                  InvalidPHPResponse('tAppGlobals.LoginCheck', Result);
                end;
            end;
        end;
    finally JSONObject.Free;
    end;
  except
  end;
  if isLogin then
    begin
      ShowLabels;
      frmMain.divNoPrijzen.Visible := False;
      frmMain.divPrijzen.Visible := True;
      frmMain.btnLogin.Visible := not isLogin;
      frmMain.btnLogout.Visible := isLogin;
      frmMain.btnNewAccount.Visible := not isLogin;
    end;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.PrettyPrint(const sJson: string): string;
var
  JO: TJSvalue;
begin
  JO := TJSJSON.Parse(sJson);
  Result := TJSJSON.stringify(JO, jsvalue(nil), 2);
end;
// ------------------------------------------------------------------------------
function tAppGlobals.AllUsers: string;
var
  REQAllUsers: THttpRequest;
  resAllUsers: TJSXMLHttpRequest;
  FullUrl: string;
begin
  REQAllUsers := THttpRequest.Create(Application);
  try
    REQAllUsers.OnResponse := AllUsersReponse { wordt uitgevoerd in AllUsersRequest.Perform() };
    REQAllUsers.Headers.Clear;
    FullUrl := phpAllUsers;
    REQAllUsers.URL := FullUrl;
    resAllUsers := await(TJSXMLHttpRequest, REQAllUsers.Perform());
    Result := trim(string(resAllUsers.response));
  except Result := GetErrorDescription(errUnhandled);
  end;
  frmMain.edtTmpInfo.Clear;
  frmMain.edtTmpInfo.Lines.Add(Result);
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.AllUsersReponse(Sender: TObject; AResponse: string);
begin
  fResult := AResponse;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.GetUserResponse(Sender: TObject; AResponse: string);
begin
  fResult := AResponse;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.GetUserError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
begin
  showmessage(ARequest.req.responseURL);
end;
// ------------------------------------------------------------------------------
function tAppGlobals.GetErrorDescription(errType: txErrorType): string;
var
  errorInfo: txError;
begin
  if xErrors.TryGetValue(errType, errorInfo) then Result := errorInfo.Omschrijving
  else Result := 'Unknown error';
end;
// ------------------------------------------------------------------------------
function tAppGlobals.FindErrorTypeByDescription(const ADescription: string): txErrorType;
var
  tmpMessage: txMessage;
begin
  if xMessages.TryGetValue(ADescription, tmpMessage) then Result := tmpMessage.Error
  else Result := errUnhandled;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.InvalidPHPResponse(a, b: string);
begin
  frmMain.edtTmpInfo.Lines.Add('FATAL ERROR IN ' + UpperCase(a) + #10 + #10 + b);
end;
// ------------------------------------------------------------------------------
function GetJsonValueAsString(Obj: TJSONObject; const Key: string; const DefaultValue: string = ''): string;
var
  JSONValue: tJSONValue;
begin
  JSONValue := Obj.GetValue(Key);
  if assigned(JSONValue) then
    begin
      if JSONValue.Value = 'null' then Result := DefaultValue
      else Result := JSONValue.Value;
    end
  else Result := DefaultValue;
end;
// ------------------------------------------------------------------------------
function GetJsonValueAsDate(Obj: TJSONObject; const Key: string; const DefaultValue: TDate = 0): TDate;
var
  JSONValue: tJSONValue;
  aYear, aMonth, aDay: word;
  aDate: TDate;
begin
  JSONValue := Obj.GetValue(Key);
  if assigned(JSONValue) then
    begin
      if (JSONValue.Value = 'null') or (JSONValue.Value = '1899-12-30') then aDate := DefaultValue
      else
        begin
          aYear := StrToInt(Copy(JSONValue.Value, 1, 4));
          aMonth := StrToInt(Copy(JSONValue.Value, 6, 2));
          aDay := StrToInt(Copy(JSONValue.Value, 9, 2));
          aDate := EncodeDate(aYear, aMonth, aDay);
        end;
      Result := aDate;
    end
  else Result := DefaultValue;
end;
// ------------------------------------------------------------------------------
function GetJsonValueAsBoolean(Obj: TJSONObject; const Key: string; const DefaultValue: boolean = False): boolean;
var
  JSONValue: tJSONValue;
begin
  JSONValue := Obj.GetValue(Key);
  if assigned(JSONValue) then Result := (SameText(JSONValue.Value.ToLower, 'true') or (JSONValue.Value = '1'))
  else Result := DefaultValue;
end;
// ------------------------------------------------------------------------------
function GetJsonValueAsDouble(Obj: TJSONObject; const Key: string; const DefaultValue: double = 0): double;
var
  JSONValue: tJSONValue;
  tmpstr: string;
begin
  JSONValue := Obj.GetValue(Key);
  if assigned(JSONValue) then
    begin
      tmpstr := JSONValue.Value;
      tmpstr := StringReplace(tmpstr, '.', ',', [rfReplaceAll]);
      Result := StrToFloat(tmpstr);
    end
  else Result := DefaultValue;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.SetValidUser(const isValid: boolean; const sNaam: string);
begin
  TSessionStorage.SetValue('IsMember', booltostr(isValid, True));
  TSessionStorage.SetValue('MemberName', sNaam);
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.ShowLabels;
begin
  with frmMain do
    begin
      with lblLoginError do
        begin
          Visible := (Caption <> EmptyStr);
        end;
      with lblLoginSuccess do
        begin
          Visible := (Caption <> EmptyStr);
        end;
    end;
end;
// ------------------------------------------------------------------------------
end.
