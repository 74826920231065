// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xTypes;
interface
type
  txErrorLevel = (elError, elWarning, elSuccess, elInfo, elFatal);
  txErrorType  = (errUnknown, //
     errNoRecords, //
     errConnectionFailed, //
     errQueryFailed, //
     errQueryPreparationFailed, //
     errEmailMissing, //
     errParamsMissing, //
     errInvalidLogging, //
     errMailSent, //
     errVerificationFailed, //
     errVerificationSuccess, //
     errInvalidToken, //
     errLoggingSuccess, //
     errLoggingFailed, //
     errAddUserSuccess, //
     errAddUserFailed, //
     errStatementPreparation, //
     errUnhandled);
  txError = record
    Omschrijving: string;
    Level: txErrorLevel;
  end;
  txMessage = record
    Error: txErrorType;
    Level: txErrorLevel;
  end;
implementation
end.
