// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : pas/xLogMessage.pas
  Contents      : www.ExEntryC.be
  Start         : 2024-10-24
  Upload history:
}
// ------------------------------------------------------------------------------
unit xLogMessage;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  System.TypInfo {GetEnumName} ,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.REST,
  WEBLib.StdCtrls,
  WEBLib.JSON,
  System.DateUtils,
  Vcl.StdCtrls,
  Vcl.Controls,
  fMain;
// ------------------------------------------------------------------------------
type
  txLogType = (Nieuw, Wijzig, Schrap, Fout, Fataal, Success);
  txLogMessage = class(tComponent)
  private
    fLogDateTime: TDateTime;
    fLogType: txLogType;
    fLogMessage: string;
    fLogTabel: string;
  private
    constructor Create(AOwner: tComponent); override;
    destructor Destroy; override;
  public
    procedure Clear;
    function UrlAddParams: string;
    // properties
    property xLogDateTime: TDateTime read fLogDateTime write fLogDateTime;
    property xLogType: txLogType read fLogType write fLogType;
    property xLogMessage: string read fLogMessage write fLogMessage;
    property xLogTabel: string read fLogTabel write fLogTabel;
  end;
  // ------------------------------------------------------------------------------
implementation
uses
  WEBLib.Storage,
  xSiteConstants,
  xAppGlobals;
// ------------------------------------------------------------------------------
procedure txLogMessage.Clear;
begin
  fLogDateTime := 0;
end;
// ------------------------------------------------------------------------------
constructor txLogMessage.Create(AOwner: tComponent);
begin
  inherited;
  Clear;
end;
// ------------------------------------------------------------------------------
destructor txLogMessage.Destroy;
begin
  inherited;
end;
// ------------------------------------------------------------------------------
function txLogMessage.UrlAddParams: string;;
begin
  Result := '?' + fldlogType + '=' + GetEnumName(typeInfo(txLogType), Ord(fLogType)) //
     + '&' + fldLogTabel + '=' + fLogTabel //
     + '&' + fldlogMessage + '=' + fLogMessage;
end;
// ------------------------------------------------------------------------------
end.
