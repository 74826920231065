// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xSiteResources;
// ------------------------------------------------------------------------------
interface
resourcestring
  // captions
  resGoHome = 'Go Home' { menu };
  resAboutExEntryC = 'About ExEntryC' { menu };
  resContactUs = 'Contact' { menu };
  resLogin = 'Log in' { knop };
  resLogout = 'Log out' { knop };
  resNewAccount = 'Nieuw account' { knop };
  resClose = 'Sluit' { knop };
  resNewsLetter = 'Nieuwsbrief';
  // errors
  errNoLogin = 'Geen login ingevuld.';
  errNoValidEmail = 'Geen geldig emailadres.';
  erroNoPassword = 'Paswoord is niet ingevuld.';
  errNoMatch = 'Paswoord verschilt van bevestiging.';
  errUserExists = 'Gebruiker is al gekend.';
  // ------------------------------------------------------------------------------
implementation
end.
