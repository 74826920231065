// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xAccount;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.REST,
  WEBLib.StdCtrls,
  WEBLib.JSON,
  System.DateUtils,
  Vcl.StdCtrls,
  Vcl.Controls,
  fMain;
// ------------------------------------------------------------------------------
type
  tMode = (Development, Production);
  txAccount = class(tComponent)
  private
    fLogin: string;
    fEmail: string;
    fNaam: string;
    fZip: string;
    fSleutel: string;
    fShortDesc: string;
    fLongDesc: string;
    fFree: string;
    fToken: string;
    fCreated: TDate;
    fCreatedBy: string;
    fModified: TDate;
    fModifiedBy: string;
    fOdooVersion: string;
    fIsTestAccount: boolean;
    fLicenseStartDate: TDate;
    fLicenseEndDate: TDate;
    fLicenseOk: boolean;
    fTrialStartDate: TDate;
    fTrialEndDate: TDate;
    fTrialOk: boolean;
    fNieuwsbrief: boolean;
    fAdres: string;
    fGemeente: string;
    fLand: string;
    fRegistratie: TDate;
    fBedrijf: string;
    fLicenseKey: string;
    fHardwareId: string;
    fVerified: boolean;
    fVerificationDate: TDate;
  private
    constructor Create(AOwner: tComponent); override;
    destructor Destroy; override;
  public
    procedure Clear;
    procedure LoadFromJson(const sObj: string);
    procedure AccountNaarScherm(aForm: TfrmMain);
    function UrlAddParams: string;
    procedure CopyFrom(src: txAccount);
    property xLogin: string read fLogin write fLogin;
    property xEmail: string read fEmail write fEmail;
    property xNaam: string read fNaam write fNaam;
    property xZip: string read fZip write fZip;
    property xSleutel: string read fSleutel write fSleutel;
    property xShortDesc: string read fShortDesc write fShortDesc;
    property xLongDesc: string read fLongDesc write fLongDesc;
    property xFree: string read fFree write fFree;
    property xCreated: TDate read fCreated write fCreated;
    property xCreatedBy: string read fCreatedBy write fCreatedBy;
    property xModified: TDate read fModified write fModified;
    property xModifiedBy: string read fModifiedBy write fModifiedBy;
    property xOdooVersion: string read fOdooVersion write fOdooVersion;
    property xIsTestAccount: boolean read fIsTestAccount write fIsTestAccount;
    property xLicenseStartDate: TDate read fLicenseStartDate write fLicenseStartDate;
    property xLicenseEndDate: TDate read fLicenseEndDate write fLicenseEndDate;
    property xLicenseOk: boolean read fLicenseOk write fLicenseOk;
    property xTrialStartDate: TDate read fTrialStartDate write fTrialStartDate;
    property xTrialEndDate: TDate read fTrialEndDate write fTrialEndDate;
    property xTrialOk: boolean read fTrialOk write fTrialOk;
    property xNieuwsbrief: boolean read fNieuwsbrief write fNieuwsbrief;
    property xAdres: string read fAdres write fAdres;
    property xGemeente: string read fGemeente write fGemeente;
    property xLand: string read fLand write fLand;
    property xRegistratie: TDate read fRegistratie write fRegistratie;
    property xBedrijf: string read fBedrijf write fBedrijf;
    property xToken: string read fToken write fToken;
    property xLicenseKey: string read fLicenseKey write fLicenseKey;
    property xHardwareId: string read fHardwareId write fHardwareId;
    property xVerified: boolean read fVerified write fVerified;
    property xVerificationDate: TDate read fVerificationDate write fVerificationDate;
  end;
  // ------------------------------------------------------------------------------
implementation
uses
  WEBLib.Storage,
  xSiteConstants,
  xAppGlobals;
// ------------------------------------------------------------------------------
procedure txAccount.AccountNaarScherm(aForm: TfrmMain);
begin
  with aForm do
    begin
      edtNewLogin.Text := fLogin;
      edtNewEmail.Text := fEmail;
      { TODO : moeten password en confirm hier niet leeg blijven?? }
      edtNewPassword.Text := EmptyStr; // fSleutel;
      edtNewConfirm.Text := EmptyStr; // fSleutel;
      edtNewName.Text := fNaam;
      edtNewCompany.Text := fBedrijf;
      edtNewAddress.Text := fAdres;
      edtNewZip.Text := fZip;
      edtNewCity.Text := fGemeente;
      edtNewCountry.Text := fLand;
      edtNewInfo.Text := fShortDesc;
      edtNewMemo.Text := fLongDesc;
      chkNewsLetter.Checked := fNieuwsbrief;
      chkTrial.Checked := (fToken = EmptyStr);
    end;
end;
// ------------------------------------------------------------------------------
procedure txAccount.Clear;
begin
  fLogin := EmptyStr;
  fEmail := EmptyStr;
  fNaam := EmptyStr;
  fZip := EmptyStr;
  fSleutel := EmptyStr;
  fShortDesc := EmptyStr;
  fLongDesc := EmptyStr;
  fToken := EmptyStr;
  fFree := EmptyStr;
  fCreated := Today;
  fCreatedBy := xMyName;
  fModified := Today;
  fModifiedBy := xMyName;
  fOdooVersion := EmptyStr;
  fIsTestAccount := true;
  fLicenseStartDate := 0;
  fLicenseEndDate := 0;
  fLicenseOk := false;
  fTrialStartDate := 0;
  fTrialEndDate := 0;
  fTrialOk := false;
  fNieuwsbrief := true;
  fAdres := EmptyStr;
  fGemeente := EmptyStr;
  fLand := EmptyStr;
  fRegistratie := Today;
  fBedrijf := EmptyStr;
  fLicenseKey := EmptyStr;
  fHardwareId := EmptyStr;
  fVerified := false;
  fVerificationDate := 0;
end;
// ------------------------------------------------------------------------------
destructor txAccount.Destroy;
begin
  inherited;
end;
// ------------------------------------------------------------------------------
procedure txAccount.LoadFromJson(const sObj: string);
var
  Obj: TJSONObject;
begin
  Obj := TJSONObject.ParseJSONValue(sObj) as TJSONObject;
  if Obj = nil then raise Exception.Create('Invalid JSON format');
  Clear;
  frmMain.edtTmpInfo.Lines.Clear;
  fLogin := GetJsonValueAsString(Obj, fldLogin);
  fEmail := GetJsonValueAsString(Obj, fldEmail);
  fNaam := GetJsonValueAsString(Obj, fldNaam);
  fZip := GetJsonValueAsString(Obj, fldZip);
  fSleutel := GetJsonValueAsString(Obj, fldSleutel);
  fShortDesc := GetJsonValueAsString(Obj, fldShortDesc);
  fLongDesc := GetJsonValueAsString(Obj, fldLongDesc);
  fFree := GetJsonValueAsString(Obj, fldFree);
  fCreated := GetJsonValueAsDate(Obj, fldCreated);
  fCreatedBy := GetJsonValueAsString(Obj, fldCreatedBy);
  fModified := GetJsonValueAsDate(Obj, fldModified);
  fModifiedBy := GetJsonValueAsString(Obj, fldModifiedBy);
  fOdooVersion := GetJsonValueAsString(Obj, fldOdooVersion);
  fIsTestAccount := GetJsonValueAsBoolean(Obj, fldIsTestAccount);
  fLicenseStartDate := GetJsonValueAsDate(Obj, fldLicenseStartdate);
  fLicenseEndDate := GetJsonValueAsDate(Obj, fldLicenseEnddate);
  fLicenseOk := GetJsonValueAsBoolean(Obj, fldLicenseOk);
  fTrialStartDate := GetJsonValueAsDate(Obj, fldTrialStartdate);
  fTrialEndDate := GetJsonValueAsDate(Obj, fldTrialEnddate);
  fTrialOk := GetJsonValueAsBoolean(Obj, fldTrialOk);
  fNieuwsbrief := GetJsonValueAsBoolean(Obj, fldNieuwsbrief);
  fAdres := GetJsonValueAsString(Obj, fldAdres);
  fGemeente := GetJsonValueAsString(Obj, fldGemeente);
  fLand := GetJsonValueAsString(Obj, fldLand);
  fRegistratie := GetJsonValueAsDate(Obj, fldRegistratie);
  fBedrijf := GetJsonValueAsString(Obj, fldBedrijf);
  fToken := GetJsonValueAsString(Obj, fldtoken);
  fLicenseKey := GetJsonValueAsString(Obj, fldLicenseKey);
  fHardwareId := GetJsonValueAsString(Obj, fldHardwareId);
  fVerified := GetJsonValueAsBoolean(Obj, fldVerified);
  fVerificationDate := GetJsonValueAsDate(Obj, fldVerificationDate);
end;
// ------------------------------------------------------------------------------
function txAccount.UrlAddParams: string;
  function Todate(a: TDate): string;
  begin
    Result := FormatDateTime(xFormatOneDate, a); // als datum nul is, dan geeft 'yyyy-mm-dd' -> 1899-12-30 terwijl 'yy-mm-dd' -> 1999-12-30, dus xFormatOneDate = 'yyyy-mm-dd'
  end;
begin
  Result := '?' + fldLogin + '=' + fLogin //
     + '&' + fldEmail + '=' + fEmail //
     + '&' + fldNaam + '=' + fNaam //
     + '&' + fldZip + '=' + fZip //
     + '&' + fldSleutel + '=' + fSleutel //
     + '&' + fldShortDesc + '=' + fShortDesc //
     + '&' + fldLongDesc + '=' + fLongDesc //
     + '&' + fldFree + '=' + fFree //
     + '&' + fldCreated + '=' + Todate(fCreated) //
     + '&' + fldCreatedBy + '=' + fCreatedBy //
     + '&' + fldModified + '=' + Todate(fModified) //
     + '&' + fldModifiedBy + '=' + fModifiedBy //
     + '&' + fldOdooVersion + '=' + fOdooVersion //
     + '&' + fldIsTestAccount + '=' + booltostr(fIsTestAccount, false) //
     + '&' + fldLicenseStartdate + '=' + Todate(fLicenseStartDate) //
     + '&' + fldLicenseEnddate + '=' + Todate(fLicenseEndDate) //
     + '&' + fldLicenseOk + '=' + booltostr(fLicenseOk, false) //
     + '&' + fldTrialStartdate + '=' + Todate(fTrialStartDate) //
     + '&' + fldTrialEnddate + '=' + Todate(fTrialEndDate) //
     + '&' + fldTrialOk + '=' + booltostr(fTrialOk, false) //
     + '&' + fldNieuwsbrief + '=' + booltostr(fNieuwsbrief, false) //
     + '&' + fldAdres + '=' + fAdres //
     + '&' + fldGemeente + '=' + fGemeente //
     + '&' + fldLand + '=' + fLand //
     + '&' + fldRegistratie + '=' + Todate(fRegistratie) //
     + '&' + fldBedrijf + '=' + fBedrijf;
  { TODO : token en 4 andere ontbreken, maar moet dat wel?? }
end;
// ------------------------------------------------------------------------------
procedure txAccount.CopyFrom(src: txAccount);
begin
  fLogin := src.xLogin;
  fEmail := src.xEmail;
  fNaam := src.xNaam;
  fZip := src.xZip;
  fSleutel := src.xSleutel;
  fShortDesc := src.xShortDesc;
  fLongDesc := src.xLongDesc;
  fFree := src.xFree;
  fCreated := src.xCreated;
  fCreatedBy := src.xCreatedBy;
  fModified := src.xModified;
  fModifiedBy := src.xModifiedBy;
  fOdooVersion := src.xOdooVersion;
  fIsTestAccount := src.xIsTestAccount;
  fLicenseStartDate := src.xLicenseStartDate;
  fLicenseEndDate := src.xLicenseEndDate;
  fLicenseOk := src.xLicenseOk;
  fTrialStartDate := src.xTrialStartDate;
  fTrialEndDate := src.xTrialEndDate;
  fTrialOk := src.xTrialOk;
  fNieuwsbrief := src.xNieuwsbrief;
  fAdres := src.xAdres;
  fGemeente := src.xGemeente;
  fLand := src.xLand;
  fRegistratie := src.xRegistratie;
  fBedrijf := src.xBedrijf;
  fToken := src.xToken;
  fHardwareId := src.xHardwareId;
  fLicenseKey := src.xLicenseKey;
  fVerified := src.xVerified;
  fVerificationDate := src.xVerificationDate;
end;
// ------------------------------------------------------------------------------
constructor txAccount.Create(AOwner: tComponent);
begin
  inherited;
  Clear;
end;
// ------------------------------------------------------------------------------
end.
